<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Inserate für den Guten Zweck</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            Für den Guten Zweck
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>{{ totalInserats }} Ergebnisse gefunden</ion-card-subtitle>
        </ion-card-header>
      </ion-card>

      <inseratCard v-for="(inserat, key) in inserats" :key="key" :id="inserat.uuid" :tags="true"></inseratCard>

      <ion-infinite-scroll
          @ionInfinite="loadMoreInserats($event)"
          threshold="100px"
          id="infinite-scroll"
      >
        <ion-infinite-scroll-content
            loading-spinner="dots"
            loading-text="Weitere Inserate werden geladen">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  toastController
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import inseratCard from '../../components/inseratCard.vue';

export default defineComponent({
  name: 'GoodCause',
  data () {
    return {
      inserats: [],
      totalInserats: 0,

      page: 1,
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    inseratCard,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  created() {



    axios.get(process.env.VUE_APP_API_URL +'/inserats?isDonation=true&isActive=true')
        .then(response => {
          this.inserats = response.data["hydra:member"];
          this.totalInserats = response.data["hydra:totalItems"];
        })
  },
  methods: {
    loadMoreInserats (ev) {
      setTimeout(() => {

        // check if there are more than 30 items
        if (this.totalInserats > 30){
          // load inserats from next page
          this.page += 1;

          axios.get(process.env.VUE_APP_API_URL + '/inserats?isDonation=true&isActive=true&page='+this.page)
              .then(response => {

                response.data["hydra:member"].forEach(element => {
                  this.inserats.push(element);
                });

              }).finally(() => {
            ev.target.complete();
            ev.target.disabled = true;
          });

        } else {
          this.openToast('Keine weiteren Inserate gefunden.', 2000);
          ev.target.complete();
          ev.target.disabled = true;
        }

      }, 500);
    },
    async openToast(message: string, duration: number, color: string) {

      if (!color){
        color = "primary";
      }

      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
  }
});

</script>

<style scoped>


</style>
