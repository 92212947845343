<template>
  <ion-card v-if="!isLoading && inserat" :router-link="getRoute()">
    <img :src="inserat.inseratImages[0].accessUrl" alt="thumbnail">
    <ion-card-header>
      <p v-if="showTags" class="tagContainer">
        <ion-badge class="tag" v-if="inserat.inseratType === 'directbuy'" color="primary">Sofortkauf</ion-badge>
        <ion-badge class="tag" v-if="inserat.inseratType === 'auction'" color="secondary">Auktion</ion-badge>
        <ion-badge class="tag" v-if="inserat.inseratType === 'gift'" color="danger">Geschenk</ion-badge>
        <ion-badge class="tag" v-if="inserat.inseratType === 'directbuy'" color="primary">{{ inserat.price }}{{ inserat.currency }}</ion-badge>
        <ion-badge class="tag" v-if="category" color="primary">{{ category.name }}</ion-badge>
      </p>
      <ion-card-subtitle>@{{ inserat.createdBy.username }} am {{ convertCreatedAt(inserat.createdAt) }} um {{ convertCreatedAtTime(inserat.createdAt) }} Uhr</ion-card-subtitle>
      <ion-card-title>{{ inserat.title }}</ion-card-title>
    </ion-card-header>
    <ion-card-content style="white-space: break-spaces;" v-if="showDescription">
      <div v-if="inserat.description.length<8">{{ inserat.description }}</div>
      <div v-else>{{ inserat.description.substring(0,60)+".." }}</div>
    </ion-card-content>
  </ion-card>

  <ion-card v-if="!isLoading && loadingError">
    <ion-card-header>
      <ion-card-title>{{ errorMessage }}</ion-card-title>
    </ion-card-header>
  </ion-card>

  <!-- Skeleton Loading Dummy -->
  <ion-card v-if="isLoading">

    <ion-skeleton-text animated style="line-height: 150px; margin-top: 0px;"></ion-skeleton-text>
    <ion-card-header>
      <p v-if="showTags" class="tagContainer">
        <ion-badge class="tag" color="primary"><ion-skeleton-text animated style="width: 50px;"></ion-skeleton-text></ion-badge>
        <ion-badge class="tag" color="primary"><ion-skeleton-text animated style="width: 50px;"></ion-skeleton-text></ion-badge>
      </p>
      <ion-card-subtitle><ion-skeleton-text animated style="line-height: 20px;"></ion-skeleton-text></ion-card-subtitle>
      <ion-card-title><ion-skeleton-text animated style="line-height: 40px; margin-top: 10px;"></ion-skeleton-text></ion-card-title>
    </ion-card-header>
    <ion-card-content style="white-space: break-spaces;" v-if="showDescription">
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard, IonCardHeader, IonCardContent, IonCardSubtitle, IonCardTitle, IonBadge, IonSkeletonText
} from '@ionic/vue';
import axios from "axios";
import moment from "moment";
export default {
  name: "inseratCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonBadge,
    IonSkeletonText
  },
  data () {
    return {

      isLoading: false,
      loadingError: false,
      errorMessage: "",
      inserat: null,
      showTags: false,
      category: null,
      showDescription: false,
      isClickable: true,
    }
  },
  props: ['id', 'tags', 'description', 'clickable'],
  created() {



    if (this.$props.tags) {
      this.showTags = true;
    }

    if (this.$props.description) {
      this.showDescription = true;
    }

    if (this.$props.clickable === false) {
      this.isClickable = false;
    }

    this.isLoading = true;
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$props.id)
        .then(response => {
          this.inserat = response.data;
        })
        .catch(error => {
            this.errorMessage = error.message;
            this.loadingError = true;
        })
        .finally(() => {
          this.isLoading = false;

          if (!this.loadingError) {
            // get category
            axios.get(process.env.VUE_APP_API_URL + this.inserat.category).then(response => {
                this.category = response.data;
            });
          }

        });
  },
  methods: {
    convertCreatedAt(date){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date){
      return moment(date).format("HH:MM");
    },
    getRoute() {
      if (this.isClickable){
        return "/inserat/" + this.inserat.uuid;
      }
      return "";
    }
  }
}
</script>

<style scoped>
.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

.tagContainer {
  margin-top: 0px;
}

.tag {
  margin-right: 8px;
}

</style>